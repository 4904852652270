<template>
  <div :class="{ 'week': true, 'is-loading': !getWeekData.days }">
    <div class="week-date-month-year">
      {{ currentMonth }}
    </div>

    <span
      v-if="getConfig.waiting_list && getInquiries.length && !getWaitingListInInquiries"
      class="waiting-list-notification"
      v-pure-html="getTranslation('blocked_reservelist_beacuse_of_cart')">
    </span>

    <div class="week-data-wrapper">
      <a
        href="#"
        :class="{ 'week-nav-prev': true, 'is-hidden': !showPrevNav }"
        @click.prevent="getWeek(-1)">
        &lsaquo;
      </a>

      <a
        v-if="!weekIsOvertake"
        href="#"
        class="week-nav-next"
        @click.prevent="getWeek(1)">
        &rsaquo;
      </a>

      <div
        v-if="getWeekData.days"
        class="week-days" >
        <div
          class="week-days-column"
          v-for="(dayData, index) of getWeekData.days"
          :key="'week-days-column-' + index">
          <div class="week-days-heading">
            <strong class="week-days-heading-day">
              {{ dayData.day.substr(0, 3) }}.
            </strong>
            <small class="week-days-heading-date">
              {{ dayData.date.split('-').slice(1).reverse().join('.') }}
            </small>
          </div>

          <div
            v-if="!weekIsOvertake"
            class="week-days-hours">
            <div
              v-if="!dayData.hours || dayData.hours <= 0"
              class="week-days-error">
              {{ dayData.message }}
            </div>

            <template v-if="dayData.hours && dayData.hours.length">
              <div
                :class="{ 'week-days-hour': true, 'is-valid': hourData.valid === 1, 'is-selected': isSelected(dayData.date, hourData.hour), 'is-in-cart': isInCart(dayData.date, hourData.hour), 'is-waiting-list': hourData.valid === -1, 'is-blocked': isBlocked(dayData.date, hourData.hour) }"
                v-for="(hourData, hindex) of filteredHours(dayData.hours, index)"
                :ref="'week-days-hour-' + index + '-' + hindex"
                :key="'week-days-hour-' + index + '-' + hindex"
                @click.prevent="selectDate(dayData.date, hourData.hour, hourData.valid, hourData.free_workers)"
                @mouseenter="showTooltip(hourData.valid, 'week-days-hour-' + index + '-' + hindex)"
                @mouseleave="hideTooltip()">
                {{ hourData.hour }}
                <!--<div
                  v-if="isInCart(dayData.date, hourData.hour)"
                  class="week-days-hour-tooltip">
                  <template v-if="getConfig.cart_enabled">
                    {{ getTranslation('termin_exisits_in_cart') }}
                  </template>
                  <template v-else>
                    {{ getTranslation('choosen_termin') }}
                  </template>
                  <template v-if="isBlocked(dayData.date, hourData.hour)">. {{ getTranslation('cant_add_more_reservation_to_termin') }}</template>
                </div>-->
              </div>
              <div
                v-if="hoursCount(dayData.hours) > 9 && !expandedRows[index]"
                class="week-days-hour-more"
                @click="expandRow(index)">
              </div>
            </template>
          </div>
        </div>
      </div>

      <div
        v-if="weekIsOvertake"
        class="week-days-error is-danger">
        {{ getTranslation('validation_max_reservation_overtake') }}
      </div>
    </div>
  </div>
</template>

<script>
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  name: 'week-view',
  mixins: [
    Translations
  ],
  computed: {
    ...mapGetters([
      'getConfig',
      'getCurrentlyBlockedHours',
      'getCurrentWeek',
      'getInquiries',
      'getWaitingListInInquiries',
      'getWeekData',
      'getFormState',
      'getSelectedWorker',
      'getDate',
      'getTime'
    ]),
    showPrevNav () {
      return this.getCurrentWeek > 0;
    },
    currentMonth () {
      if (this.getWeekData && this.getWeekData.days && this.getWeekData.days[0]) {
        let firstDayDate = this.getWeekData.days[0].date.split('-');
        let dateObject = new Date(firstDayDate[0], firstDayDate[1] - 1, firstDayDate[2]);
        let monthName = dateObject.toLocaleString(this.$store.state.pluginConfig.lang, { month: 'long' });
        return monthName[0].toUpperCase() + monthName.slice(1) + ' ' + firstDayDate[0];
      }

      return '';
    },
    weekIsOvertake () {
      return this.getWeekData.valid_week === -1;
    }
  },
  data () {
    return {
      expandedRows: [false, false, false, false, false, false]
    };
  },
  methods: {
    getWeek (offset) {
      let currentWeek = Math.max(0, this.getCurrentWeek + offset);
      this.$store.commit('setCurrentWeek', currentWeek);
      this.expandedRows = [false, false, false, false, false, false];
    },
    selectDate (date, time, isValid, freeWorkers) {
      if (!isValid) {
        return;
      }

      freeWorkers = freeWorkers.map(worker => worker.id);

      if (!this.getSelectedWorker && freeWorkers.length) {
        if (freeWorkers.length === 1) {
          let preselectedWorker = freeWorkers[0];
          this.$store.commit('setPreSelectedWorker', preselectedWorker);
        } else {
          let randomIndex = Math.floor(Math.random() * freeWorkers.length);
          let preselectedWorker = freeWorkers[randomIndex];
          this.$store.commit('setPreSelectedWorker', preselectedWorker);
        }
      } else {
        this.$store.commit('setPreSelectedWorker', 0);
      }

      if (date === this.getFormState.date && time === this.getFormState.time) {
        this.$store.dispatch('setDate', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
        this.$store.dispatch('setTime', {
          time: '',
          isWaitingList: isValid
        });
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
      } else {
        this.$store.dispatch('setDate', date);
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: date });

        Vue.nextTick(() => {
          this.$store.dispatch('setTime', {
            time: time,
            isWaitingList: isValid
          });
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: time });
          this.$store.commit('setDateWeekOffset', this.getCurrentWeek);
          this.$store.commit('removeError', 'date');
        });
      }
    },
    isSelected (date, time) {
      return this.getFormState.date === date && this.getFormState.time === time;
    },
    isInCart (date, time) {
      return this.getCurrentlyBlockedHours(date, time).length > 0;
    },
    isBlocked (date, time) {
      return this.isInCart(date, time) && this.getConfig.block_user_reservation && this.getConfig.require_mail && this.getConfig.customers_based_on === 'email';
    },
    filteredHours (hours, index) {
      if (this.getConfig.waiting_list && !this.getInquiries.length) {
        let hoursToDisplay = hours.filter(hourData => hourData.valid !== 0);

        if (this.expandedRows[index]) {
          return hoursToDisplay;
        }

        return hoursToDisplay.slice(0, 9);
      }

      let hoursToDisplay = hours.filter(hourData => hourData.valid > 0);

      if (this.expandedRows[index]) {
        return hoursToDisplay;
      }

      return hoursToDisplay.slice(0, 9);
    },
    hoursCount (hours) {
      if (this.getConfig.waiting_list && !this.getInquiries.length) {
        return hours.filter(hourData => hourData.valid !== 0).length;
      }

      return hours.filter(hourData => hourData.valid > 0).length;
    },
    showTooltip (itemValid, refID) {
      if (itemValid === -1) {
        this.$bus.$emit('show-tooltip', this.getTranslation('waiting_list'), this.$refs[refID][0]);
      }
    },
    hideTooltip () {
      this.$bus.$emit('hide-tooltip');
    },
    expandRow (rowIndex) {
      Vue.set(this.expandedRows, rowIndex, true);
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../assets/scss/variables.scss';

.week {
  position: relative;

  .waiting-list-notification {
    color: $color-text-medium;
    display: block;
    font-size: 14px;
    line-height: 1.6;
    padding: 10px 0;
    text-align: center;

    & > strong {
      color: $color-primary;
      font-weight: bold;
    }
  }

  .week-data-wrapper {
    position: relative;
  }

  &-date-month-year {
    font-weight: 500;
    position: absolute;
    right: 0;
    top: -51px;
  }

  &-nav {
    background: $color-bg-light;
    border-radius: $border-radius-small;
    display: flex;
    padding: 10px;

    &-prev,
    &-next {
      border: 1px solid $color-border;
      border-radius: $border-radius-small;
      color: $color-dark;
      font-size: 24px;
      height: 47px;
      line-height: 20px;
      overflow: hidden;
      position: absolute;
      right: 0;
      text-align: center;
      text-decoration: none;
      text-indent: -999px;
      top: 0;
      transform-origin: center center;
      transition: $transition-basic;
      width: 32px;

      &:before {
        border: 2px solid $color-border-alt;
        border-left: none;
        border-bottom: none;
        content: "";
        height: 8px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform-origin: center center;
        transform: translateY(-50%) rotate(45deg);
        transition: all .25s ease-out;
        width: 8px;
      }

      &:hover {
        text-decoration: none;

        &:before {
          transform:  translateY(-50%) translateX(3px) rotate(45deg);
        }
      }
    }

    &-prev {
      left: 0;
    }

    &-next {
      right: 0;
    }

    &-prev {
      left: 0;
      right: auto;

      &:before {
        left: 12px;
        right: auto;
        transform: translateY(-50%) rotate(-135deg);
      }

      &.is-hidden {
        opacity: 0.5;
        pointer-events: none;
      }

      &:hover {
        text-decoration: none;

        &:before {
          transform: translateY(-50%) translateX(-3px) rotate(-135deg);
        }
      }
    }
  }

  &-days {
    display: flex;
    margin: 10px auto 0 auto;
    width: calc(100% - 80px);

    &-column {
      margin: 0 8px;
      width: calc((100% / 6) - 16px);
    }

    &-heading {
      background: $color-light;
      border: 1px solid $color-border;
      border-radius: $border-radius-small;
      display: flex;
      flex-wrap: wrap;

      &-day {
        color: $color-text;
        display: block;
        font-size: 13px;
        font-weight: bold;
        line-height: 1;
        padding: 8px 3px 4px 3px;
        text-align: center;
        width: 100%;
      }

      &-date {
        display: block;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        padding: 0 0 8px 0;
        text-align: center;
        width: 100%;
      }
    }

    &-error {
      color: $color-text-light;
      font-size: 13px;
      font-style: italic;
      font-weight: bold;
      margin: 5px 0;
      padding: 18px 2px;
      text-align: center;

      &.is-danger {
        color: $color-danger;
      }
    }

    &-hour {
      background: $color-primary-light;
      border: 1px solid $color-primary-light;
      border-radius: $border-radius-small;
      color: $color-primary;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      margin: 12px 0;
      padding: 10px;
      position: relative;
      text-align: center;
      white-space: nowrap;
      width: 100%;

      &.is-valid {
        background: $color-primary-light;
        border-color: $color-primary-light;
        color: $color-primary;
      }

      &.is-selected {
        background: $color-primary;
        border-color: $color-primary;
        color: $color-light;
        cursor: pointer;
        font-weight: bold;
      }

      &.is-in-cart {
        background: $color-light;
        border: 2px solid $color-primary;
        color: $color-primary;
        cursor: not-allowed;
        font-weight: bold;

        &.is-valid {
          cursor: pointer;
        }

        &.is-selected {
          background: $color-primary;
          border-color: $color-primary;
          color: $color-light;
          font-weight: bold;
        }
      }

      &.is-waiting-list {
        position: relative;

        &:after {
          background: $color-danger;
          bottom: 3px;
          content: "";
          height: 2px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 75%;
        }
      }

      &-more {
        border: 1px solid $color-border;
        border-radius: $border-radius-small;
        color: $color-dark;
        cursor: pointer;
        height: 30px;
        overflow: hidden;
        position: relative;
        text-align: center;
        text-decoration: none;
        text-indent: -999px;
        transition: $transition-basic;
        width: 100%;

        &:before {
          border: 2px solid $color-border-alt;
          border-left: none;
          border-bottom: none;
          content: "";
          height: 8px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform-origin: center center;
          transform: translateX(-50%) translateY(-75%) rotate(135deg);
          transition: all .25s ease-out;
          width: 8px;
        }

        &:hover {
          text-decoration: none;

          &:before {
            transform: translateX(-50%) translateY(-40%) rotate(135deg);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .week {
    &-days {
      flex-wrap: wrap;

      &-column {
        margin-bottom: 20px;
        width: calc((100% / 3) - 20px);
      }
    }
  }
}

@media (max-width: 720px) {
  .week {
    &-days {
      &-column {
        width: calc((100% / 2) - 20px);
      }
    }

    &-date-month-year {
      position: relative;
      top: -2px;
      text-align: center;
    }
  }
}

@media (max-width: 480px) {
  .week {
    &-days {
      &-column {
        width: calc(100% - 20px);
      }
    }
  }
}
</style>

<template>
  <div
    v-if="selectedServiceRequiresPeopleNumber()"
    :class="{
      'people-section': true,
      'is-active': isActive,
      'is-without-children-field': !selectedServiceRequiresChildren && selectedServiceIsDailyService
    }">
    <div class="w-50">
      <div :class="{
        'field': true,
        'is-required': true,
        'is-invalid': hasError('people-number')
      }">
        <label
          for="bookero-plugin-people-number"
          v-pure-html="getTranslation('people_count')">
        </label>
        <div class="people-number-wrapper">
          <input
            type="number"
            v-model="peopleNumber"
            id="bookero-plugin-people-number"
            @change="emitChange()"
            :min="minPeople"
            :max="maxPeople"
            step="1" />
          <div
            class="people-number-plus"
            @click="increasePeople()">+</div>
          <div
            class="people-number-minus"
            @click="decreasePeople()">–</div>
        </div>
      </div>

      <div
        v-if="hasError('people-number')"
        class="error-message">
        {{ getTranslation('validation_wrong_people_number') }}
      </div>

      <small
          v-if="servicePeopleRange"
          class="field-note">
          {{ servicePeopleRange }}
      </small>
    </div>

    <div class="w-50">
      <div
        v-if="selectedServiceRequiresChildren"
        :class="{
          'field': true,
          'is-required': true,
          'is-invalid': hasError('children-number')
        }">
        <label
          for="bookero-plugin-children-number"
          v-pure-html="getTranslation('children_count')">
        </label>
        <div class="children-number-wrapper">
          <input
            type="number"
            v-model="childrenNumber"
            id="bookero-plugin-children-number"
            @change="emitChange()"
            :min="0"
            step="1" />
          <div
            class="children-number-plus"
            @click="increaseChildren()">+</div>
          <div
            class="children-number-minus"
            @click="decreaseChildren()">–</div>
        </div>
      </div>

      <div
        v-if="hasError('children-number')"
        class="error-message">
        {{ getTranslation('validation_wrong_children_number') }}
      </div>
    </div>
  </div>
</template>

<script>
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'people',
  mixins: [
    Translations
  ],
  props: [
    'isActive'
  ],
  computed: {
    ...mapGetters([
      'hasError',
      'addError',
      'getPeopleNumber',
      'getChildrenNumber',
      'getServices',
      'getSelectedService',
      'selectedServiceRequiresChildren',
      'selectedServiceIsDailyService'
    ]),
    selectedServiceData () {
      if (this.getSelectedService !== 0) {
        let service = this.getServices.filter(service => service.id === parseInt(this.getSelectedService, 10));

        if (!service || !service.length) {
          return false;
        }

        return service[0];
      }

      return false;
    },
    servicePeopleRange () {
      if (this.getSelectedService !== 0) {
        let service = this.getServices.filter(service => service.id === parseInt(this.getSelectedService, 10))[0];

        if (!service) {
          return '';
        }

        if (service.validate_people_number) {
          return this.getTranslation('min_max_people_number').replace('%min', service.min_people_number).replace('%max', service.max_people_number);
        }
      }

      return '';
    },
    minPeople () {
      if (this.getSelectedService !== 0) {
        let service = this.getServices.filter(service => service.id === parseInt(this.getSelectedService, 10))[0];

        if (!service) {
          return '';
        }

        if (service.validate_people_number) {
          return service.min_people_number;
        }
      }

      return '';
    },
    maxPeople () {
      if (this.getSelectedService !== 0) {
        let service = this.getServices.filter(service => service.id === parseInt(this.getSelectedService, 10))[0];

        if (!service) {
          return '';
        }

        if (service.validate_people_number) {
          return service.max_people_number;
        }
      }

      return '';
    }
  },
  data () {
    return {
      peopleNumber: 1,
      childrenNumber: 0,
      isInvalid: false
    };
  },
  mounted () {
    this.peopleNumber = this.getPeopleNumber;
    this.childrenNumber = this.getChildrenNumber;
    this.checkPeopleNumberLimits();
    this.emitChange();
    this.$bus.$on('bookero-plugin-reset-people-number', this.resetPeopleNumber);
    this.$bus.$on('bookero-plugin-validate', this.validate);
  },
  methods: {
    emitChange () {
      this.peopleNumber = parseInt(this.peopleNumber.toString().replace(/[^0-9]/gmi, ''), 10);
      this.childrenNumber = parseInt(this.childrenNumber.toString().replace(/[^0-9]/gmi, ''), 10);
      this.checkPeopleNumberLimits();
      this.$store.commit('removeError', 'people-number');
      this.$store.commit('removeError', 'children-number');

      if (this.selectedServiceRequiresChildren) {
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:people-changed', {
          number: this.peopleNumber,
          children_number: this.children_number,
          total_number: this.peopleNumber + this.children_number
        });
        this.$store.commit('setPeopleNumber', this.peopleNumber);
        this.$store.commit('setChildrenNumber', this.childrenNumber);
      } else {
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:people-changed', {
          number: this.peopleNumber,
          children_number: 0,
          total_number: this.peopleNumber
        });
        this.$store.commit('setPeopleNumber', this.peopleNumber);
      }
    },
    checkPeopleNumberLimits () {
      if (this.minPeople !== '' && this.peopleNumber < this.minPeople) {
        this.peopleNumber = this.minPeople;
      }

      if (this.maxPeople !== '' && this.peopleNumber > this.maxPeople) {
        this.peopleNumber = this.maxPeople;
      }
    },
    selectedServiceRequiresPeopleNumber () {
      let result = true;
      let currentService = this.getServices.filter(service => service.id === parseInt(this.getSelectedService, 10));

      if (currentService.length) {
        return !!currentService[0].people_required;
      }

      return result;
    },
    increasePeople () {
      this.peopleNumber = parseInt(this.peopleNumber.toString().replace(/[^0-9]/gmi, ''), 10);

      if (isNaN(this.peopleNumber)) {
        if (this.minPeople) {
          this.peopleNumber = this.minPeople;
          this.emitChange();
        } else {
          this.peopleNumber = 1;
          this.emitChange();
        }
      } else {
        if (this.maxPeople !== '' && this.peopleNumber < this.maxPeople) {
          this.peopleNumber++;
          this.emitChange();
        } else if (this.maxPeople === '') {
          this.peopleNumber++;
          this.emitChange();
        }
      }
    },
    decreasePeople () {
      if (this.peopleNumber > 1 || isNaN(this.peopleNumber)) {
        this.peopleNumber = parseInt(this.peopleNumber.toString().replace(/[^0-9]/gmi, ''), 10);

        if (isNaN(this.peopleNumber)) {
          if (this.minPeople) {
            this.peopleNumber = this.minPeople;
            this.emitChange();
          } else {
            this.peopleNumber = 1;
            this.emitChange();
          }
        } else {
          if (this.minPeople !== '' && this.peopleNumber > this.minPeople) {
            this.peopleNumber--;
            this.emitChange();
          } else if (this.minPeople === '') {
            this.peopleNumber--;
            this.emitChange();
          }
        }
      }
    },
    resetPeopleNumber () {
      let prevPeopleNumber = this.peopleNumber;
      let prevChildrenNumber = this.childrenNumber;

      if (this.minPeople !== '') {
        this.peopleNumber = this.minPeople;
        this.childrenNumber = 0;
      } else {
        this.peopleNumber = 1;
        this.childrenNumber = 0;
      }

      if (
        this.peopleNumber !== prevPeopleNumber ||
        (this.selectedServiceRequiresChildren && this.childrenNumber !== prevChildrenNumber)
      ) {
        this.emitChange();
      }
    },
    increaseChildren () {
      this.childrenNumber = parseInt(this.childrenNumber.toString().replace(/[^0-9]/gmi, ''), 10);

      if (isNaN(this.childrenNumber)) {
        this.childrenNumber = 0;
      } else {
        this.childrenNumber++;
      }

      this.emitChange();
    },
    decreaseChildren () {
      if (isNaN(this.childrenNumber)) {
        this.childrenNumber = 0;
      }

      if (this.childrenNumber > 0) {
        this.childrenNumber = parseInt(this.childrenNumber.toString().replace(/[^0-9]/gmi, ''), 10);

        if (isNaN(this.childrenNumber)) {
          this.childrenNumber = 0;
        } else {
          this.childrenNumber--;
        }

        this.emitChange();
      }
    },
    validate () {
      if (!this.selectedServiceData) {
        return;
      }

      if (this.selectedServiceRequiresPeopleNumber()) {
        if (!this.peopleNumber || isNaN(this.peopleNumber)) {
          this.$store.commit('addError', 'people-number');
        }

        if (this.selectedServiceData.validate_people_number) {
          if (this.peopleNumber < this.selectedServiceData.min_people_number || this.peopleNumber > this.selectedServiceData.max_people_number) {
            this.$store.commit('addError', 'people-number');
          }
        }
      }

      if (this.selectedServiceRequiresChildren) {
        if (isNaN(this.childrenNumber)) {
          this.$store.commit('addError', 'children-number');
        }
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-reset-people-number', this.resetPeopleNumber);
    this.$bus.$off('bookero-plugin-validate', this.validate);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.people-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;

  &:not(.is-active) {
    opacity: .25;
    pointer-events: none;
  }

  .w-50 {
    width: calc(50% - 10px);
  }

  #bookero-plugin-people-number,
  #bookero-plugin-children-number {
    padding: 0 50px;
    text-align: center;
    width: 100%;
  }

  .people-number-wrapper,
  .children-number-wrapper {
    position: relative;
    width: 100%;

    .people-number-plus,
    .people-number-minus,
    .children-number-plus,
    .children-number-minus {
      align-items: center;
      color: darken($color-border, 35%);
      cursor: pointer;
      display: flex;
      font-size: 20px;
      height: 48px;
      justify-content: center;
      position: absolute;
      top: 0;
      user-select: none;
      width: 50px;

      &:active,
      &:focus,
      &:hover {
        color: $color-primary;
      }
    }

    .people-number-plus,
    .children-number-plus {
      right: 0;
    }

    .people-number-minus,
    .children-number-minus {
      left: 0;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.is-without-children-field {
    width: 50%;

    .w-50 {
      width: calc(100% - 10px);
    }
  }

  .error-message {
    width: 100%;
  }
}
</style>

<template>
  <div
    v-if="isVisible"
    class="alert-popup-overlay">
    <div class="alert-popup">
      <h2 class="alert-popup-header">
        {{ title }}
      </h2>

      <a
        class="alert-popup-close"
        href="#close"
        :title="getTranslation('close')"
        @click.prevent="setVisibility(false)"></a>

      <div class="alert-popup-content">
        {{ desc }}
      </div>
    </div>
  </div>
</template>

<script>
import Translations from './mixins/Translations';

export default {
  name: 'alert-popup',
  mixins: [
    Translations
  ],
  data () {
    return {
      isVisible: false,
      title: '',
      desc: ''
    };
  },
  mounted () {
    this.$bus.$on('bookero-plugin-show-alert-popup', this.showPopup);
  },
  methods: {
    showPopup (content) {
      this.title = content.title;
      this.desc = content.desc;
      this.setVisibility(true);
    },
    setVisibility (newState) {
      this.isVisible = newState;
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-show-alert-popup', this.showPopup);
  }
}
</script>

<style scoped lang="scss">
@import './../assets/scss/variables.scss';

.alert-popup {
  background: $color-light;
  border-radius: $border-radius-small;
  color: $color-text;
  justify-content: center;
  left: 50%;
  max-height: 90vh;
  max-width: 90%;
  padding: 30px 30px 30px 30px;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 560px;
  z-index: 1000000000001;

  &-header {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    justify-content: center;
    margin: 0 auto 20px auto;
    width: 90%;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1.4;
    font-size: 15px;
    text-align: left;
  }

  &-close {
    height: 39px;
    opacity: .5;
    position: absolute;
    right: 24px;
    text-decoration: none;
    top: 32px;
    transform: translateY(-50%);
    transition: all .3s ease-out;
    width: 24px;
    z-index: 1;

    &:before,
    &:after {
      content: "";
      background: $color-dark;
      height: 2px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      width: 30px;
    }

    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  &-overlay {
    background: rgba(0, 0, 0, .5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000000000000;
  }
}

@media (max-width: 540px) {
  .alert-popup {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: 10px 15px 15px 15px;
    width: 100%;

    &-close {
      right: 10px;
      top: 20px;
    }
  }
}
</style>

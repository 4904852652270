<template>
  <div
    :class="{ 'reservation-type-section': true, 'is-empty': !serviceIsCyclic }">
    <div
      v-if="serviceIsCyclic"
      :class="{ 'field': true, 'is-required': true, 'is-loading': terminsAreLoading, 'is-invalid': hasError('periodicity') }">
      <label for="bookero-plugin-periodicity" v-pure-html="getTranslation('reservation_type')"></label>
      <select
        v-if="!getConfig.use_styled_dropdowns"
        id="bookero-plugin-periodicity"
        v-model="selectedPeriodicity"
        @change="changedPeriodicity"
        :class="{ 'is-loading': terminsAreLoading }">
        <option
          v-for="(periodicity, index) of periodicities"
          :key="'periodicity-' + index"
          :value="periodicity.id">
          {{ periodicity.name }}
          <template v-if="showPaymentsInForm && getPeriodicityPriceValue(periodicity) !== '0.00' && !!getPeriodicityPriceValue(periodicity)">
             ({{ getPeriodicityPriceWithCurrency(periodicity) }})
          </template>
        </option>
      </select>

      <v-select
        v-if="getConfig.use_styled_dropdowns"
        v-model="selectedPeriodicity"
        @input="changedPeriodicity"
        :class="{ 'is-loading': terminsAreLoading }"
        :options="filteredPeriodicities"
        :custom-label="periodicityNameById"
        :placeholder="getTranslation('reservation_periodicity')"
        :allowEmpty="false"
        :showLabels="false"
        :searchable="false">
        <div slot="noOptions">
          {{ getTranslation('the_list_is_empty') }}
        </div>
      </v-select>

      <div
        v-if="hasError('periodicity')"
        class="error-message">
        {{ getTranslation('validation_choose_type_of_reservation') }}
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyUtils from './../helpers/CurrencyUtils';
import MultiSelect from 'vue-multiselect';
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'reservation-type',
  mixins: [
    Translations
  ],
  components: {
    'v-select': MultiSelect
  },
  data () {
    return {
      isInvalid: false,
      terminsAreLoading: true,
      periodicities: [],
      selectedPeriodicity: 0
    };
  },
  computed: {
    ...mapGetters([
      'getConfig',
      'getCurrency',
      'getFormState',
      'getServices',
      'getSelectedService',
      'hasError',
      'selectedServiceHasCustomDurations',
      'showPaymentsInForm'
    ]),
    filteredPeriodicities () {
      return this.periodicities.map(periodicity => periodicity.id);
    },
    serviceIsCyclic () {
      if (this.getSelectedService !== 0) {
        let service = this.getServices.filter(service => service.id === this.getSelectedService)[0];

        if (!service) {
          return false;
        }

        return !!service.periodicity;
      }

      return false;
    }
  },
  mounted () {
    this.getPeriodicity();
    this.$bus.$on('bookero-plugin-validate', this.validate);
  },
  watch: {
    getSelectedService (oldValue, newValue) {
      if (this.serviceIsCyclic) {
        this.serviceChanged();
      }
    }
  },
  methods: {
    periodicityNameById (periodicityID) {
      if (periodicityID === 0) {
        return this.getTranslation('reservation_periodicity');
      }

      let periodicity = this.periodicities.filter(periodicity => periodicity.id === periodicityID)[0];

      if (this.showPaymentsInForm && this.getPeriodicityPriceValue(periodicity) !== '0.00' && !!this.getPeriodicityPriceValue(periodicity)) {
        return periodicity.name + ' (' + this.getPeriodicityPriceWithCurrency(periodicity) + ')';
      }

      return periodicity.name;
    },
    serviceChanged () {
      this.selectedPeriodicity = 0;
      this.$store.commit('setPeriodicity', 0);

      setTimeout(() => {
        if (this.getSelectedService !== 0) {
          this.getPeriodicity();
        } else {
          this.periodicities = [];
        }
      }, 0);
    },
    changedPeriodicity () {
      this.$store.commit('setPeriodicity', this.selectedPeriodicity);
      this.$store.commit('removeError', 'periodicity');
    },
    getPeriodicity () {
      let pluginConfig = this.$store.state.pluginConfig;
      let serviceToCheck = this.getSelectedService;
      this.terminsAreLoading = true;

      this.$http.get(this.$endpointURL + '/getPeriodicity', {
        params: {
          bookero_id: pluginConfig.id,
          lang: pluginConfig.lang,
          service: serviceToCheck
        }
      }).then(res => {
        if (res.data && res.data.data && serviceToCheck === this.getSelectedService) {
          this.periodicities = JSON.parse(JSON.stringify(res.data.data));
          this.selectedPeriodicity = this.getFormState.selectedPeriodicity;

          if (this.periodicities.length && this.selectedPeriodicity === 0) {
            this.selectedPeriodicity = this.periodicities[0].id;
            this.changedPeriodicity();
          }
        }

        this.terminsAreLoading = false;
      }).catch(() => {
        this.terminsAreLoading = false;
      });
    },
    getPeriodicityPriceValue (cycle) {
      if (this.selectedServiceHasCustomDurations) {
        let durations = cycle.custom_duration_prices;
        let selectedDuration = durations.filter(duration => duration.custom_duration_id === this.getFormState.selectedCustomDuration);

        if (selectedDuration.length) {
          return selectedDuration[0].price;
        }
      }

      return cycle.price;
    },
    getPeriodicityPriceWithCurrency (cycle) {
      if (this.selectedServiceHasCustomDurations) {
        let durations = cycle.custom_duration_prices;
        let selectedDuration = durations.filter(duration => duration.custom_duration_id === this.getFormState.selectedCustomDuration);

        if (selectedDuration.length) {
          if (selectedDuration[0].price > 0) {
            return CurrencyUtils.getQuote(selectedDuration[0].price, this.getCurrency);
          }

          return CurrencyUtils.getQuote(selectedDuration[0].price, this.getCurrency);
        }
      }

      if (cycle.price > 0) {
        return CurrencyUtils.getQuote(cycle.price, this.getCurrency);
      }

      return CurrencyUtils.getQuote(cycle.price, this.getCurrency);
    },
    validate () {
      if (this.serviceIsCyclic && this.getFormState.selectedPeriodicity === 0) {
        this.$store.commit('addError', 'periodicity');
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-validate', this.validate);
  }
}
</script>

<style scoped lang="scss">
.reservation-type-section {
  padding: 10px 0;
  width: 100%;

  &.is-empty {
    padding: 0;
  }

  .is-loading {
    opacity: .5;
    pointer-events: none;
  }
}
</style>

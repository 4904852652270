<template>
  <div
    v-if="((!getWaitingListInInquiries && filteredMethodsData.length) || hasDiscountCode) && ((getPrice !== '0.00' && !!getPrice) || discountValue > 0)"
    class="payment-section">
    <h3
      v-if="((!getWaitingListInInquiries && filteredMethodsData.length > 1) || hasDiscountCode) && ((getPrice !== '0.00' && !!getPrice) || discountValue > 0)"
      class="bookero-plugin-form-heading">
      <template v-if="filteredMethodsData.length === 1 && hasDiscountCode">{{ getTranslation('summary_payment_one_method') }}</template>
      <template v-else>{{ getTranslation('summary_payment_method') }}</template>
    </h3>

    <div
      v-if="!getWaitingListInInquiries && filteredMethodsData.length"
      :class="{ 'payment-methods': true, 'is-hidden': filteredMethodsData.length === 1 }">
      <div
        v-for="(method, index) of filteredMethodsData"
        :key="'payment-method-' + index"
        @click="changeMethod(method.id)"
        :class="{ 'payment-methods-item': true, 'is-active': paymentMethod === method.id }">
        <div class="payment-methods-item-wrapper">
          <img
            :src="method.image"
            class="payment-methods-item-icon"
            alt="" />
          <span class="payment-methods-item-name">
            {{ method.name }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="hasDiscountCode"
      class="field bookero-plugin-discount-code-section">
      <switcher
        v-model="iHaveDiscountCode"
        :label="getTranslation('summary_discount_checkbox')"
        key="toggle-discount-code"
        @click.native="setDiscountCodeVisibility()" />

      <div
        v-if="iHaveDiscountCode"
        :class="{ 'bookero-plugin-discount-code-wrapper': true, 'is-loading': discountDataLoading }">
        <input
          v-model="discountCode"
          @input="updatedDiscountCode"
          @blur="updatedDiscountCode"
          :placeholder="getTranslation('summary_put_discount')"
          id="bookero-plugin-discount-code"
          :class="{ 'is-valid': discountCode !== '' && discountCodeStatus === 'valid', 'is-invalid': discountCode !== '' && discountCodeStatus === 'invalid' }" />

        <span
          :class="{ 'bookero-plugin-discount-code-status': true, 'is-valid': discountCode !== '' && discountCodeStatus === 'valid', 'is-invalid': discountCode !== '' && discountCodeStatus === 'invalid' }">
          <span
            v-if="discountCode !== '' && discountCodeStatus === 'valid'"
            v-pure-html="getTranslation('discount_for_amount').replace('%s', '-' + discountValue + '%')">
          </span>
          <span
            v-if="discountCode !== '' && discountCodeStatus === 'invalid'"
            v-pure-html="getTranslation('discount_is_invalid')">
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import CommonMethods from './../mixins/CommonMethods';
import Switcher from './../elements/Switcher';
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'payment-and-discount',
  mixins: [
    CommonMethods,
    Translations
  ],
  components: {
    'switcher': Switcher
  },
  computed: {
    ...mapGetters([
      'getAllPaymentMethods',
      'getConfig',
      'getFilteredPaymentMethods',
      'getFormState',
      'getCommonPaymentMethods',
      'getDiscountValue',
      'getInquiries',
      'getPrice',
      'getSelectedService',
      'getServices',
      'hasDiscountCode',
      'selectedServiceIsDailyService',
      'selectedServiceRequiresChildren',
      'getWaitingListInInquiries'
    ]),
    filteredMethodsData () {
      if (this.getConfig.cart_enabled) {
        return this.getCommonPaymentMethods;
      }

      return this.getFilteredPaymentMethods;
    },
    filteredMethods () {
      if (this.getConfig.cart_enabled) {
        return this.getCommonPaymentMethods.map(method => method.id);
      }

      return this.getFilteredPaymentMethods.map(method => method.id);
    }
  },
  data () {
    return {
      discountCode: '',
      isInvalid: false,
      iHaveDiscountCode: false,
      paymentMethod: 0,
      discountCodeStatus: '',
      discountValue: 0,
      discountDataLoading: false
    };
  },
  watch: {
    getSelectedService () {
      this.resetPaymentMethod();
    },
    iHaveDiscountCode (newValue) {
      if (!newValue) {
        this.discountCode = '';
        this.updatedDiscountCode();
      }
    }
  },
  mounted () {
    this.discountCode = this.getFormState.discountCode;
    this.discountCodeStatus = this.getFormState.discountCodeStatus;
    this.iHaveDiscountCode = this.getFormState.discountCodeVisibility;
    this.discountValue = this.getDiscountValue;
    this.updatePaymentMethod();

    if (this.discountCode !== '') {
      this.discountCodeCheck();
    }

    this.$bus.$on('bookero-plugin-payment-methods-updated', this.updatePaymentMethod);
  },
  methods: {
    methodNameById (methodID) {
      if (methodID === 0) {
        return this.getTranslation('payment_method');
      }

      if (this.getConfig.cart_enabled) {
        return this.getCommonPaymentMethods.filter(method => method.id === methodID)[0].name;
      } else {
        return this.getFilteredPaymentMethods.filter(method => method.id === methodID)[0].name;
      }
    },
    updatedDiscountCode () {
      this.discountCodeStatus = '';
      this.$store.commit('setDiscountCode', this.discountCode);
      this.debouncedDiscountCodeCheck();
    },
    debouncedDiscountCodeCheck: debounce(function () {
      this.discountCodeCheck();
    }, 1000),
    discountCodeCheck () {
      this.discountCodeStatus = '';
      this.discountDataLoading = true;
      let pluginConfig = this.$store.state.pluginConfig;
      let paramsData = [];
      paramsData = paramsData.concat(this.$store.state.formState.specialParamsValues);
      paramsData = paramsData.concat(this.$store.state.formState.paramsValues);

      let params = {
        'data': {
          'name': this.getFormState.name,
          'phone': this.getFormState.phone,
          'parameters': paramsData.filter(obj => !(Object.keys(obj).length === 0 && obj.constructor === Object)),
          'services_names': this.getServiceNames(),
          'services_values': this.getServiceValues()
        }
      };

      let inquiries = [];

      if (!this.getConfig.cart_enabled) {
        let workerID = null;

        if (this.getFormState.selectedWorker) {
          workerID = this.getFormState.selectedWorker;
        } else if (this.getFormState.preSelectedWorker) {
          workerID = this.getFormState.preSelectedWorker;
        }

        let inquiryData = {
          plugin_comment: JSON.stringify(params),
          phone: this.getFormState.phone,
          people: this.getFormState.peopleNumber,
          date: this.getFormState.date,
          hour: this.getFormState.time,
          email: this.getFormState.email,
          service: this.getFormState.selectedService,
          worker: workerID,
          periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
          custom_duration_id: this.getFormState.selectedCustomDuration,
          plugin_inquiry_id: null
        };

        if (this.selectedServiceIsDailyService) {
          inquiryData.days = this.getFormState.daysNumber;
          inquiryData.worker = null;
          inquiryData.hour = null;

          if (this.selectedServiceRequiresChildren) {
            inquiryData.children = this.getFormState.childrenNumber;
          }
        }

        inquiries.push(inquiryData);
      } else {
        inquiries = this.getInquiries;
        inquiries = this.fillInquiriesWithCustomerData(inquiries);
      }

      this.$http.post(this.$endpointURL + '/checkDiscountCode', {
        bookero_id: pluginConfig.id,
        discount_code: this.getFormState.discountCode,
        configuration_payment_id: this.getFormState.selectedPaymentMethod,
        inquiries: inquiries
      }).then(res => {
        this.discountCodeStatus = 'invalid';

        for (let discount in res.data.data.discounts) {
          if (res.data.data.discounts[discount].is_discount) {
            this.discountCodeStatus = 'valid';
            this.discountValue = parseFloat(res.data.data.discounts[discount].discount_percent);
            break;
          }
        }

        this.$store.commit('setDiscountCodeStatus', this.discountCodeStatus);
        this.$store.commit('setPriceBeforeDiscount', parseFloat(res.data.data.total_price_before));
        this.$store.commit('setPriceAfterDiscount', parseFloat(res.data.data.total_price_after));
        this.$store.commit('setDiscountValue', this.discountValue);
        this.discountDataLoading = false;
      }).catch(() => {
        this.discountCodeStatus = '';
        this.discountDataLoading = false;
      });
    },
    changeMethod (methodID) {
      this.paymentMethod = methodID;
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:payment-method-changed', { paymentMethodID: this.paymentMethod });
      this.$store.commit('setPaymentMethod', this.paymentMethod);
    },
    resetPaymentMethod () {
      let methods = this.getFilteredPaymentMethods;

      if (this.getConfig.cart_enabled) {
        methods = this.getCommonPaymentMethods;
      }

      if (methods.filter(method => method.is_default)[0]) {
        this.paymentMethod = methods.filter(method => method.is_default)[0].id;
      } else {
        this.paymentMethod = methods[0].id;
      }

      this.$store.commit('setPaymentMethod', this.paymentMethod);
    },
    setDiscountCodeVisibility () {
      this.$store.commit('setDiscountCodeVisibility', this.iHaveDiscountCode);
    },
    updatePaymentMethod () {
      if (
        this.getFormState.selectedPaymentMethod !== 0 &&
        (
          (this.getConfig.cart_enabled && this.getCommonPaymentMethods.map(method => method.id).indexOf(this.getFormState.selectedPaymentMethod) > -1) ||
          (!this.getConfig.cart_enabled && this.getFilteredPaymentMethods.map(method => method.id).indexOf(this.getFormState.selectedPaymentMethod) > -1)
        )
      ) {
        this.paymentMethod = this.getFormState.selectedPaymentMethod;
      } else {
        if (this.getConfig.cart_enabled) {
          if (this.getCommonPaymentMethods.filter(method => method.is_default)[0]) {
            this.paymentMethod = this.getCommonPaymentMethods.filter(method => method.is_default)[0].id;
          }
        } else {
          if (this.getFilteredPaymentMethods.filter(method => method.is_default)[0]) {
            this.paymentMethod = this.getFilteredPaymentMethods.filter(method => method.is_default)[0].id;
          }
        }
      }

      if (!this.paymentMethod) {
        if (this.getConfig.cart_enabled) {
          if (this.getCommonPaymentMethods[0]) {
            this.paymentMethod = this.getCommonPaymentMethods[0].id;
          }
        } else if (this.getFilteredPaymentMethods[0]) {
          this.paymentMethod = this.getFilteredPaymentMethods[0].id;
        }
      }

      if (!this.paymentMethod && !this.getConfig.cart_enabled) {
        let defaultMethod = this.getAllPaymentMethods.filter(method => method.is_default);

        if (defaultMethod.length) {
          this.paymentMethod = defaultMethod[0].id;
        }
      }

      this.$store.commit('setPaymentMethod', this.paymentMethod);
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-payment-methods-updated', this.updatePaymentMethod);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.payment-section {
  padding: 10px 0;
  width: 100%;

  .bookero-plugin-form-heading {
    padding: 10px 0;
  }

  .bookero-plugin-discount-code-section {
    border-top: 1px solid $color-border;
    padding: 35px 0 0 0;

    .bookero-plugin-discount-code-wrapper {
      align-items: center;
      display: flex;
      padding-top: 20px;

      & > input {
        width: 50%;

        &.is-valid {
          border-bottom: 2px solid $color-success;
        }

        &.is-invalid {
          border-bottom: 2px solid $color-danger;
        }
      }

      & > span {
        font-size: 15px;
        padding-left: 2rem;
        width: 50%;
      }

      &.is-loading {
        position: relative;

        &:after {
          animation: 1s discount-spinner linear infinite;
          border: 2px solid $color-border;
          border-left-color: transparent!important;
          border-radius: 50%;
          content: "";
          height: 20px;
          left: calc(50% - 24px);
          position: absolute;
          top: calc(50% + 9px);
          transform: translateX(-50%) translateY(-50%);
          width: 20px;
        }
      }
    }

    .bookero-plugin-discount-code-status {
      &.is-valid {
        color: $color-success;
      }

      &.is-invalid {
        color: $color-danger;
      }
    }
  }

  .payment-methods {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1% 40px -1%;

    &.is-hidden {
      display: none;
    }

    &-item {
      align-items: center;
      border: 1px solid $color-border;
      border-radius: $border-radius-small;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin: 1%;
      height: 120px;
      position: relative;
      transition: border .3s ease-out, background-color .3s ease-out;
      width: 23%;

      &-wrapper {
        align-items: center;
        background: $color-light;
        border-radius: 2px;
        display: flex;
        height: calc(100% - 4px);
        justify-content: center;
        position: absolute;
        width: calc(100% - 4px);
      }

      &:hover {
        border: 1px solid $color-primary;
      }

      &.is-active {
        background: $color-primary;
        border: 1px solid $color-primary;
      }

      &-icon {
        margin: -24px 0 0 0;
        width: 100px;
      }

      &-name {
        bottom: 10px;
        color: $color-text-medium;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.2;
        left: 5%;
        position: absolute;
        text-align: center;
        width: 90%;
      }
    }
  }
}

@keyframes discount-spinner {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}

@media (max-width: 580px) {
  .payment-section {
    .payment-methods {
      &-item {
        width: 48%;
      }
    }

    .bookero-plugin-discount-code-section {
      .bookero-plugin-discount-code-wrapper {
        flex-wrap: wrap;

        & > input {
          width: 100%;
        }

        & > span {
          padding-left: 0rem;
          padding-top: 1rem;
          width: 100%;
        }
      }
    }
  }
}
</style>

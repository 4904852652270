<template>
  <div
    v-if="isVisible"
    class="result-popup-overlay">
    <div :class="{ 'result-popup': true, 'is-mixed': currentStatus === 'mixed' }">
      <div class="result-popup-wrapper">
        <a
          class="result-popup-close"
          href="#close"
          :title="getTranslation('close')"
          @click.prevent="setVisibility(false)"></a>

        <div
          v-if="currentStatus === 'success' && !paymentUrl"
          class="result-popup-icon is-success">
          <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="clock" stroke="none" stroke-width="1" fill="#FFFFFF" fill-rule="evenodd">
              <path d="M21.284,11.513 L14.5,20.2780214 L9.386,14.377 C9.02694504,14.0754127 8.53267285,13.9920384 8.09455599,14.1591581 C7.65643912,14.3262777 7.34326047,14.717654 7.27627671,15.1817537 C7.20929294,15.6458534 7.39902028,16.1098144 7.772,16.394 L13.6928013,23.5532924 C14.2066827,23.9642178 14.8949923,24.0183116 15.360493,23.5532924 L23.113,13.336 C23.5861968,12.8261169 23.5707537,12.0331143 23.0780642,11.5420409 C22.5853746,11.0509676 21.7923255,11.0381304 21.284,11.513 Z M15.5,0 C9.23083521,0 3.57897275,3.77645376 1.17986725,9.5684068 C-1.21923826,15.3603598 0.106875953,22.0271862 4.53984489,26.4601551 C8.97281383,30.893124 15.6396402,32.2192383 21.4315932,29.8201328 C27.2235462,27.4210273 31,21.7691648 31,15.5 C30.9906308,6.94347022 24.0565298,0.00936917568 15.5,0 L15.5,0 Z M15.5,28.417 C8.36613789,28.417 2.583,22.6338621 2.583,15.5 C2.583,8.36613789 8.36613789,2.583 15.5,2.583 C22.6338621,2.583 28.417,8.36613789 28.417,15.5 C28.4087341,22.6304355 22.6304355,28.4087341 15.5,28.417 L15.5,28.417 Z"></path>
            </g>
          </svg>
        </div>

        <div
          v-if="currentStatus === 'failure' || currentStatus === 'mixed'"
          class="result-popup-icon is-rejected">
          <svg xmlns="http://www.w3.org/2000/svg" width="23.719" height="23.719" viewBox="0 0 23.719 23.719">
            <path fill="#ffffff" fill-rule="evenodd" d="M448.257,100.534a11.861,11.861,0,1,0,0,16.773A11.874,11.874,0,0,0,448.257,100.534Zm-1.4,15.376a9.884,9.884,0,1,1,0-13.978A9.9,9.9,0,0,1,446.859,115.91Zm-5.591-6.989,2.8-2.8a0.988,0.988,0,1,0-1.4-1.4l-2.8,2.8-2.8-2.8a0.988,0.988,0,1,0-1.4,1.4l2.795,2.8-2.795,2.795a0.989,0.989,0,1,0,1.4,1.4l2.8-2.8,2.8,2.8a0.988,0.988,0,1,0,1.4-1.4Z" transform="translate(-428 -97.063)"/>
          </svg>
        </div>

        <div
          v-if="currentStatus === 'waiting' || currentStatus === 'waiting_list'"
          class="result-popup-icon is-waiting">
          <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
            <path id="clock" fill="#ffffff" file-rule="evenodd" d="M242.284,102.513l-6.88,6.879-5.018-4.015a1.292,1.292,0,1,0-1.614,2.017l5.921,4.737a1.292,1.292,0,0,0,1.72-.095l7.7-7.7A1.292,1.292,0,0,0,242.284,102.513ZM235.5,93A15.5,15.5,0,1,0,251,108.5,15.517,15.517,0,0,0,235.5,93Zm0,28.417A12.917,12.917,0,1,1,248.417,108.5,12.932,12.932,0,0,1,235.5,121.417Z" transform="translate(-220 -93)"/>
          </svg>
        </div>

        <h2 class="result-popup-header">
          <template v-if="currentStatus === 'waiting' || currentStatus === 'waiting_list'">
            {{ getTranslation('thanks_message') }}
          </template>
          <template v-if="currentStatus === 'mixed'">
            {{ getTranslation('some_of_reservations_rejected') }}
          </template>
          <template v-if="currentStatus === 'success'">
            <template v-if="paymentUrl">{{ getTranslation('reservation_needs_payment') }}</template>
            <template v-else>{{ getTranslation('confirmed') }}</template>
          </template>
          <template v-if="currentStatus === 'failure'">{{ getTranslation('reject') }}</template>
        </h2>

        <p
          v-if="currentStatus === 'waiting'"
          class="result-popup-content"
          v-pure-html="getConfig.msg_sent">
        </p>

        <p
          v-if="currentStatus === 'waiting_list'"
          class="result-popup-content"
          v-pure-html="getConfig.msg_waiting_list">
        </p>

        <p
          v-if="currentStatus === 'success' && !paymentUrl && getConfig.msg_confirmed"
          class="result-popup-content"
          v-pure-html="getConfig.msg_confirmed">
        </p>

        <p
          v-if="currentStatus === 'success' && !paymentUrl && !getConfig.msg_confirmed"
          class="result-popup-content">
          {{ getTranslation('confirmed_message') }}
        </p>

        <p
          v-if="currentStatus === 'success' && paymentUrl && !getConfig.auto_payment && getConfig.msg_payment"
          class="result-popup-content"
          v-pure-html="getConfig.msg_payment">
        </p>

        <p
          v-if="currentStatus === 'success' && paymentUrl && getConfig.auto_payment && getConfig.msg_payment"
          class="result-popup-content"
          v-pure-html="getConfig.msg_auto_payment">
        </p>

        <p
          v-if="currentStatus === 'success' && paymentUrl && !getConfig.msg_payment"
          class="result-popup-content"
          v-pure-html="getConfig.msg_payment">
          {{ getTranslation('confirmed_message_with_payment') }}
        </p>

        <p
          v-if="currentStatus === 'failure' && getConfig.msg_denied"
          class="result-popup-content"
          v-pure-html="getConfig.msg_denied">
        </p>

        <p
          v-if="currentStatus === 'failure' && !getConfig.msg_denied"
          class="result-popup-content">
          {{ getTranslation('reject_message') }}
        </p>

        <p
          v-if="currentStatus === 'mixed'"
          class="result-popup-content">
          {{ getTranslation('some_of_inquiries_have_been_rejected') }}
        </p>

        <vue-custom-scrollbar
          v-if="currentStatus === 'mixed'"
          class="result-popup-content">
          <inquiry-status
            v-for="(inquiry, index) of inquiriesData"
            :key="'inquiry-' + index"
            :listIndex="index"
            :cartEnabled="getConfig.cart_enabled"
            :inquiryData="inquiry"
            :servicesBackup="servicesBackup"
            :workersBackup="workersBackup"
            :inquiryStatus="getStatus(inquiry.plugin_inquiry_id)" />
        </vue-custom-scrollbar>

        <p
          v-if="(currentStatus === 'success' || currentStatus === 'mixed') && paymentUrl"
          class="result-popup-content-payment-link">
          <span
            v-if="paymentQuote"
            class="result-popup-content-payment-quote">
            <span>{{ getTranslation('summary_total_cost') }}:</span>
            <strong>{{ quoteToPay }}</strong>
          </span>

          <a
            :href="paymentUrl"
            class="result-popup-content-paylink"
            target="_blank"
            @click="setVisibility(false)">
            {{ getTranslation('pay_button') }}
          </a>
        </p>

        <p
          v-if="(currentStatus === 'success' || currentStatus === 'mixed') && paymentUrl"
          class="result-popup-content-payment-ssl">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17">
            <path id="ssl" fill="#cccccc" fill-rule="evenodd" d="M182.091,107.083v-2.252a10.288,10.288,0,0,1,3.409-.581v-1.417a11.639,11.639,0,0,0-4.336.84l-0.437.174v3.236a7.237,7.237,0,0,0,4.513,6.837l0.519-1.311A5.859,5.859,0,0,1,182.091,107.083ZM178,102.125v4.958A10.18,10.18,0,0,0,185.5,117a10.18,10.18,0,0,0,7.5-9.917v-4.958A14.3,14.3,0,0,0,178,102.125Zm13.636,4.958a8.724,8.724,0,0,1-6.136,8.439,8.724,8.724,0,0,1-6.136-8.439v-4.122a12.965,12.965,0,0,1,12.272,0v4.122Z" transform="translate(-178 -100)"/>
          </svg>
          {{ getTranslation('ssl_certified') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyUtils from './helpers/CurrencyUtils';
import InquiryStatus from './elements/InquiryStatus';
import Tracking from './mixins/Tracking';
import Translations from './mixins/Translations';
import { mapGetters } from 'vuex';
import vueCustomScrollbar from 'vue-custom-scrollbar'

export default {
  name: 'result-popup',
  mixins: [
    Tracking,
    Translations
  ],
  components: {
    'inquiry-status': InquiryStatus,
    'vue-custom-scrollbar': vueCustomScrollbar
  },
  computed: {
    ...mapGetters([
      'getDiscountCodeStatus',
      'getDiscountValue',
      'getInquiries',
      'getConfig',
      'getCurrency',
      'getPrice',
      'getFormState'
    ]),
    quoteToPay () {
      return CurrencyUtils.getQuote(this.paymentQuote, this.paymentCurrency);
    }
  },
  data () {
    return {
      isVisible: false,
      inquiries: false,
      inquiriesData: false,
      currentStatus: '',
      paymentUrl: '',
      paymentQuote: null,
      paymentCurrency: null,
      servicesBackup: null,
      workersBackup: null
    };
  },
  mounted () {
    this.$bus.$on('bookero-plugin-show-result-popup', this.showPopup);
  },
  methods: {
    showPopup (inquiriesResponse, inquiries, servicesBackup = false, workersBackup = false) {
      if (servicesBackup !== false) {
        this.servicesBackup = servicesBackup;
      }

      if (workersBackup !== false) {
        this.workersBackup = workersBackup;
      }

      this.inquiriesData = inquiries;
      this.inquiries = inquiriesResponse.inquiries.map(inquiry => {
        return {
          id: inquiry.plugin_inquiry_id,
          status: inquiry.status
        };
      });
      let statuses = inquiriesResponse.inquiries.map(inquiry => inquiry.status);
      let hasNotAccepted = statuses.indexOf(2) > -1 || statuses.indexOf(1) > -1;
      let hasSuccess = statuses.indexOf(3) > -1;
      let hasFailure = statuses.indexOf(4) > -1 || statuses.indexOf(5) > -1;
      let hasWaiting = statuses.indexOf(6) > -1;

      if (!this.getConfig.auto_accept) {
        if (this.inquiries.length && hasFailure && !hasWaiting && !hasSuccess && !hasNotAccepted) {
          this.currentStatus = 'failure';
          this.trackingFailedPurchase();
        } else {
          this.currentStatus = 'waiting';
        }
      } else {
        this.paymentUrl = inquiriesResponse.payment_url;
        this.paymentQuote = inquiriesResponse.payment_quote;
        this.paymentCurrency = inquiriesResponse.payment_currency;

        if (hasSuccess && !hasNotAccepted && !hasFailure && !hasWaiting) {
          this.currentStatus = 'success';
          this.trackingPurchase(this.getFormState.cartID, this.getPrice, this.getCurrency);

          if (this.getDiscountCodeStatus === 'valid' && this.getFormState.discountCode !== '') {
            this.trackingDiscountCode();
          }

          if (this.paymentUrl && this.getConfig.auto_payment) {
            window.location.href = this.paymentUrl;
          }
        } else if (hasFailure && !hasSuccess && !hasNotAccepted && !hasWaiting) {
          this.currentStatus = 'failure';
          this.trackingFailedPurchase();
        } else if (hasWaiting && !hasSuccess && !hasFailure && !hasNotAccepted) {
          this.currentStatus = 'waiting_list';
          this.trackingWaitingPurchase();
        } else {
          this.currentStatus = 'mixed';
          this.trackingMixedPurchase(JSON.parse(JSON.stringify(this.inquiries)), this.getCurrency);
        }
      }

      this.setVisibility(true);
    },
    setVisibility (newState) {
      this.isVisible = newState;
    },
    getStatus (inquiryID) {
      let foundedInquiry = this.inquiries.filter(inquiry => inquiry.id === inquiryID);

      if (foundedInquiry.length) {
        return foundedInquiry[0].status;
      }

      return -1;
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-show-result-popup', this.showPopup);
  }
}
</script>

<style scoped lang="scss">
@import './../assets/scss/variables.scss';

.result-popup {
  background: $color-light;
  border-radius: $border-radius-small;
  justify-content: center;
  left: 50%;
  max-width: 90%;
  padding: 20px 30px 40px 30px;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 640px;
  z-index: 1000000000001;

  &-header {
    color: $color-text-dark;
    display: flex;
    font-size: 24px;
    font-weight: 500;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
  }

  &-icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 64px;
    justify-content: center;
    margin: 40px auto 0 auto;
    width: 64px;

    & > svg {
      display: block;
      height: 32px;
      width: 32px;
    }

    &-loader {
      animation: spinner 1s linear infinite;
      border: 3px solid $color-primary;
      border-left-color: $color-light!important;
      border-radius: $border-radius-full;
      display: block;
      height: 64px;
      margin: 30px auto;
      width: 64px;
    }

    &.is-success {
      background: $color-success;
    }

    &.is-rejected {
      background: $color-danger;
    }

    &.is-waiting {
      background: $color-primary;
    }
  }

  &-content {
    color: $color-text;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1.4;
    margin: 10px 0;
    max-height: 35vh;
    overflow: auto;
    overflow-x: hidden;
    padding: 0;
    text-align: center;

    &-payment {
      font-weight: bold;
      text-align: center;

      &-ssl {
        align-items: center;
        color: $color-text-light;
        display: flex;
        font-size: 13px;
        font-weight: 600;
        justify-content: center;
        margin: 40px 0 0 0;
        opacity: .8;
        padding: 0;
        text-align: center;

        & > svg {
          margin: 0 8px 0 0;
        }
      }

      &-link {
        align-items: center;
        border: 1px solid $color-border;
        border-radius: $border-radius-small;
        display: flex;
        margin: 50px 0 0 0;
        padding: 20px;

        .result-popup-content-payment-quote {
          color: $color-text;
          width: 100%;

          & > span {
            display: block;
            font-size: 16px;
            padding-bottom: 5px;
          }

          & > strong {
            color: $color-text-dark;
            display: block;
            font-size: 24px;
            font-weight: 500;
            width: 100%;
          }
        }

        .result-popup-content-paylink {
          align-items: center;
          display: flex;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          margin: 0;
          padding: 10px 60px 10px 30px;
          position: relative;
          text-transform: none;
          white-space: nowrap;

          &:after {
            border: 2px solid $color-light;
            border-left: none;
            border-bottom: none;
            content: "";
            height: 8px;
            position: absolute;
            right: 28px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            transition: all .2s ease-out;
            width: 8px;
          }
        }

        a {
          background: $color-primary;
          border: none;
          border-radius: $border-radius-small;
          color: $color-light;
          cursor: pointer;
          display: inline-block;
          font-size: 20px;
          margin: 10px 0 0 0;
          padding: 10px 30px;
          text-decoration: none;

          &:active,
          &:focus,
          &:hover {
            background: darken($color-primary, 10%);
            color: $color-light;
            text-decoration: none;
          }
        }
      }
    }
  }

  &-close {
    height: 39px;
    opacity: .5;
    position: absolute;
    right: 24px;
    top: 32px;
    transform: translateY(-50%);
    transition: all .3s ease-out;
    width: 24px;
    z-index: 1;

    &:before,
    &:after {
      content: "";
      background: $color-dark;
      height: 2px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      width: 30px;
    }

    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  &-overlay {
    background: rgba(0, 0, 0, .25);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000000000000;
  }

  &.is-mixed {
    padding: 20px 30px 10px 30px;

    .result-popup-content-payment-link {
      margin: 20px 0 0 0;
    }

    .result-popup-icon {
      margin: 10px auto 0 auto;
    }

    .result-popup-content-payment-ssl {
      margin: 10px 0 0 0;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 540px) {
  .result-popup {
    align-items: center;
    display: flex;
    height: 100%;
    max-width: 100%;
    padding: 10px 15px;
    text-align: center;
    width: 100%;

    &.is-mixed {
      padding: 10px;
    }

    &-content-payment-link {
      flex-wrap: wrap;

      .result-popup-content-payment-quote {
        margin: 0 0 20px 0;
        text-align: center;
        width: 100%;
      }

      .result-popup-content-paylink {
        justify-content: center;
        width: 100%;
      }
    }
  }
}

@media (max-height: 700px) {
  .result-popup.is-mixed {
    .result-popup-close {
      right: 10px;
      top: 20px;
    }

    .result-popup-icon {
      display: none;
    }

    .result-popup-header {
      font-size: 18px;
      margin: 0 0 5px 0;

      & + .result-popup-content {
        font-size: 14px;
        margin: 5px 0 0 0;
      }
    }

    .result-popup-content-payment-link {
      padding: 10px;

      .result-popup-content-payment-quote {
        margin: 0 0 8px 0;

        span {
          display: inline;
          font-size: 14px;
        }

        strong {
          display: inline;
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-height: 500px) {
  .result-popup.is-mixed {
    .result-popup-content-payment-link {
      margin: 0;
    }

    .result-popup-content-payment-ssl {
      display: none;
    }
  }
}
</style>

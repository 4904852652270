<template>
  <div
    v-if="isVisible"
    class="details-popup-overlay">
    <div class="details-popup">
      <h2 class="details-popup-header">
        <template v-if="getConfig.cart_enabled">{{ getTranslation('cart_order') }} {{ listIndex }}</template>
        <template v-else>{{ getTranslation('cart_your_order') }}</template>
      </h2>

      <a
        class="details-popup-close"
        href="#close"
        :title="getTranslation('close')"
        @click.prevent="setVisibility(false)"></a>

      <vue-custom-scrollbar class="details-popup-content">
        <h3
          v-if="hasServices"
          class="details-popup-subheader">
          {{ getTranslation('cart_inquiry_service', true) }}
        </h3>

        <div
          v-if="hasServices"
          class="details-popup-content-item">
          <div class="details-popup-content-item-label">
            <template v-if="getServiceCategoryNameByServiceID(reservationDetails.service)">
              {{ getServiceCategoryNameByServiceID(reservationDetails.service) }}:
            </template>
            <template v-else>
              {{ getTranslation('cart_inquiry_service') }}:
            </template>
          </div>
          <div class="details-popup-content-item-value">
            {{ getServiceName(reservationDetails.service) }}
          </div>
        </div>

        <div
          v-if="reservationDetails.custom_duration_id"
          class="details-popup-content-item">
          <div class="details-popup-content-item-label">
            {{ getTranslation('custom_duration_label') }}:
          </div>

          <div class="details-popup-content-item-value">
            {{ getCustomDurationLabel(reservationDetails.service, reservationDetails.custom_duration_id) }}
          </div>
        </div>

        <div
          v-if="reservationServiceRequiresPeopleNumber()"
          class="details-popup-content-item">
          <div class="details-popup-content-item-label">
            {{ getTranslation('people_count') }}:
          </div>
          <div class="details-popup-content-item-value">
            {{ reservationDetails.people }}
          </div>
        </div>

        <div
          v-if="reservationServiceRequiresChildrenNumber()"
          class="details-popup-content-item">
          <div class="details-popup-content-item-label">
            {{ getTranslation('children_count') }}:
          </div>
          <div class="details-popup-content-item-value">
            {{ reservationDetails.children }}
          </div>
        </div>

        <div
          v-if="!reservationServiceIsDailyService && hasWorkers && !getCustomConfigField('hide_worker_info')"
          class="details-popup-content-item">
          <div class="details-popup-content-item-label">
            {{ getTranslation('cart_inquiry_worker') }}:
          </div>
          <div class="details-popup-content-item-value">
            {{ getWorkerName(reservationDetails.worker) }}
          </div>
        </div>

        <h3 class="details-popup-subheader">
          {{ getTranslation('term') }}
        </h3>

        <div class="details-popup-content-item">
          <span class="details-popup-termin">
            {{ reservationDetails.date }}
            <template v-if="reservationServiceIsDailyService && reservationDetails.days">
              &mdash; {{ calculateEndDate(reservationDetails) }}
            </template>
            <template v-if="!reservationServiceIsDailyService && getHoursVisibility">
              {{ getTranslation('cart_inquiry_hour') }} {{ reservationDetails.hour }}
            </template>
          </span>
          <span
            v-for="(termin, index) of reservationDetails.connectedTermins"
            :key="'connected-termin-' + index"
            class="details-popup-termin">
            <template v-if="getHoursVisibility">
              {{ termin.start_at.replace(' ', ' ' + getTranslation('cart_inquiry_hour') + ' ').replace(/\:00$/gmi, '') }}
            </template>
            <template v-else>
              {{ termin.start_at.split(' ')[0] }}
            </template>
          </span>
        </div>

        <h3
          v-if="(hasParams || hasSpecialParams) && params.length"
          class="details-popup-subheader">
          {{ getTranslation('summary_additional_info') }}
        </h3>

        <div
          v-if="(hasParams || hasSpecialParams) && params.length"
          class="details-popup-content-item">
            <div class="details-popup-content-params-list">
              <div
                v-for="(param, index) of params"
                :key="'param-' + index"
                class="details-popup-content-params-list-item">
                <span class="details-popup-content-params-list-item-label">
                  {{ param.label }}
                </span>
                <span v-pure-html="param.value"></span>
              </div>
            </div>
        </div>

        <template v-if="showPaymentsInSummary">
          <div
            v-if="inquiryPrice.replace(/[^0-9,.]/gmi, '') !== '0,00'"
            class="details-popup-price">
            {{ getTranslation('summary_cost') }}: <span class="details-popup-price-quote">{{ inquiryPrice }}</span>
          </div>
          <small
            v-if="getServicePeriodicityPaymentType(this.reservationDetails.service)"
            class="details-popup-single-reservation-price">
            {{ getTranslation('price_for_single_reservation') }}
          </small>
        </template>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import Translations from './mixins/Translations';
import CurrencyUtils from './helpers/CurrencyUtils';
import { mapGetters } from 'vuex';
import vueCustomScrollbar from 'vue-custom-scrollbar';

export default {
  name: 'details-popup',
  mixins: [
    Translations
  ],
  components: {
    'vue-custom-scrollbar': vueCustomScrollbar
  },
  computed: {
    ...mapGetters([
      'getConfig',
      'getCurrency',
      'getServiceCategoryNameByServiceID',
      'getServiceCustomLabel',
      'getServiceName',
      'getServices',
      'getWorkerName',
      'hasParams',
      'hasServices',
      'hasSpecialParams',
      'hasPaymentMethods',
      'hasWorkers',
      'getParamName',
      'getInquiryPrice',
      'peopleAreRequired',
      'getHoursVisibility',
      'getCustomDurationLabel',
      'showPaymentsInSummary',
      'getCustomConfigField',
      'getServicePeriodicityPaymentType'
    ]),
    params () {
      let paramsOrdering = this.getConfig.parameters.map(param => param.id);
      let paramsData = JSON.parse(this.reservationDetails.plugin_comment);
      paramsData = paramsData.data.parameters.filter(param => this.paramIsUsedInService(+param.id)).map(param => {
        return {
          id: +param.id,
          label: this.getParamName(+param.id),
          value: this.formattedParam(param.value)
        }
      });
      paramsData.sort((itemA, itemB) => {
        return paramsOrdering.indexOf(itemA.id) - paramsOrdering.indexOf(itemB.id);
      });
      paramsData = paramsData.filter(param => param.value.trim() !== '');

      return paramsData;
    },
    inquiryPrice () {
      return CurrencyUtils.getQuote(this.getInquiryPrice(this.reservationDetails.plugin_inquiry_id), this.getCurrency);
    },
    reservationServiceIsDailyService () {
      let serviceID = this.reservationDetails.service;
      let services = this.getServices;
      let usedService = services.filter(service => service.id === serviceID);

      if (usedService[0]) {
        return usedService[0].available_in_dates === 3;
      }

      return false;
    }
  },
  data () {
    return {
      isVisible: false,
      reservationDetails: false,
      listIndex: ''
    };
  },
  mounted () {
    this.$bus.$on('bookero-plugin-show-details-popup', this.showPopup);
  },
  methods: {
    showPopup (reservationDetails, listIndex) {
      this.reservationDetails = reservationDetails;
      this.listIndex = listIndex;
      this.setVisibility(true);
    },
    setVisibility (newState) {
      this.isVisible = newState;
    },
    reservationServiceRequiresPeopleNumber () {
      let result = this.peopleAreRequired;

      if (this.hasServices) {
        let currentService = this.getServices.filter(service => service.id === parseInt(this.reservationDetails.service, 10));

        if (currentService.length) {
          return !!currentService[0].people_required;
        }
      }

      return result;
    },
    reservationServiceRequiresChildrenNumber () {
      let result = false;

      if (this.hasServices) {
        let currentService = this.getServices.filter(service => service.id === parseInt(this.reservationDetails.service, 10));

        if (currentService.length) {
          return !!currentService[0].children_required;
        }
      }

      return result;
    },
    formattedParam (value) {
      if (Array.isArray(value)) {
        return value.join(', ');
      }

      return value;
    },
    calculateEndDate (inquiryData) {
      let serviceEndsInTheNextDay = false;
      let serviceData = this.getServices.filter(service => service.id === inquiryData.service);
      let daysOffset = inquiryData.days;
      let startDateParts = inquiryData.date.split('-').map(n => parseInt(n, 10));

      if (serviceData && serviceData[0] && serviceData[0].daily_end_available === 1) {
        serviceEndsInTheNextDay = true;
      }

      if (!serviceEndsInTheNextDay) {
        daysOffset--;
      }

      let endDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2], 12, 0, 0, 0);
      endDate.setDate(endDate.getDate() + daysOffset);

      return [endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()].map(n => n < 10 ? '0' + n : n).join('-');
    },
    paramIsUsedInService (paramID) {
      let foundedParam = this.getConfig.parameters.filter(param => param.id === paramID);

      if (foundedParam[0] && foundedParam[0].connected_services.indexOf(this.reservationDetails.service) > -1) {
        return true;
      }

      return false;
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-show-details-popup', this.showPopup);
  }
}
</script>

<style scoped lang="scss">
@import './../assets/scss/variables.scss';

.details-popup {
  background: $color-light;
  border-radius: $border-radius-small;
  color: $color-text;
  justify-content: center;
  left: 50%;
  max-height: 90vh;
  max-width: 90%;
  padding: 30px 30px 30px 30px;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 560px;
  z-index: 1000000000001;

  &-header {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 10px 0;
    width: 100%;
  }

  &-subheader {
    border-top: 1px solid $color-border;
    font-size: 20px;
    font-weight: 300;
    margin: 20px 0 10px 0;
    padding: 20px 0 0 0;
    width: 100%;

    &:first-child {
      border-top: none;
      margin-top: 10px;
      padding-top: 0;
    }
  }

  &-termin {
    display: block;
    padding: 2px 0;
    width: 100%;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    justify-content: center;
    line-height: 1.4;
    margin: 0;
    max-height: calc(90vh - 94px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    text-align: left;

    &-item {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 0;
      width: 100%;

      &-label {
        font-weight: bold;
        padding-right: 5px;
      }
    }

    &-params-list {
      width: 100%;

      &-item {
        padding: 2px 0;
        width: 100%;

        &-label {
          font-weight: bold;
          padding-right: 5px;
        }
      }
    }
  }

  &-close {
    height: 39px;
    opacity: .5;
    position: absolute;
    right: 24px;
    text-decoration: none;
    top: 32px;
    transform: translateY(-50%);
    transition: all .3s ease-out;
    width: 24px;
    z-index: 1;

    &:before,
    &:after {
      content: "";
      background: $color-dark;
      height: 2px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      width: 30px;
    }

    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  &-overlay {
    background: rgba(0, 0, 0, .5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000000000000;
  }

  &-price {
    padding-top: 20px;
    text-align: right;
    width: 100%;

    &-quote {
      font-size: 24px;
    }
  }

  &-single-reservation-price {
    display: block;
    text-align: right;
    width: 100%;
  }
}

@media (max-width: 540px) {
  .details-popup {
    border-radius: 0;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: 10px 15px 15px 15px;
    width: 100%;

    &-close {
      right: 10px;
      top: 20px;
    }

    &-content {
      max-height: calc(100% - 30px);
    }
  }
}
</style>
